import { mainnet, optimismSepolia } from "viem/chains";

const { REACT_APP_DOMAIN, REACT_APP_STAGE, REACT_APP_DEVELOPMENT } = process.env;

let domain;
if (REACT_APP_DOMAIN) {
  domain = REACT_APP_DOMAIN;
}
else if (window.location.hostname.includes("amplifyapp.com")) {
  // PR preview works on https://dev.series.fairmint.co/
  domain = "dev.series.fairmint.co";
}
else {
  domain = window.location.hostname;
}

const config = {
  dev: {
    privyAppId    : "clge6cxv5001ajy08n329vdx7",
    apiEndpoint   : "https://api.series-dev.fairmint.co",
    wsEndpoint    : "wss://ws.series-dev.fairmint.co",
    gtmId         : "GTM-WKQQRQB8",
    intercomAppId : "v7e5s2ll",
    USDC          : {
      chain   : optimismSepolia,
      address : "0xf74eBB85fADE9FDB1085194335b58823B98DCd47"
    },
  },
  staging: {
    privyAppId    : "clmi1x42400d9l50fqa9doz8d",
    apiEndpoint   : "https://api.series-staging.fairmint.co",
    wsEndpoint    : "wss://ws.series-staging.fairmint.co",
    gtmId         : "GTM-WKQQRQB8",
    intercomAppId : "v7e5s2ll",
    USDC          : {
      chain   : optimismSepolia,
      address : "0xf74eBB85fADE9FDB1085194335b58823B98DCd47"
    },
  },
  production: {
    privyAppId    : "clm7wfbpp001pmj0fd93ahj9k",
    apiEndpoint   : "https://api.series.fairmint.co",
    wsEndpoint    : "wss://ws.series.fairmint.co",
    gtmId         : "GTM-TVJJFD2J",
    intercomAppId : "zvhi5494",
    USDC          : {
      chain   : mainnet,
      address : "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48"
    },
  },
  common: {
    domain,
    sentryDSN: "https://e300360bb0ec189a4a4f143eef63e1af@o320781.ingest.us.sentry.io/4507261222125568",
  }
};

const configuration = {
  ...config.common,
  ...config[REACT_APP_STAGE],
};

// disable console.log() and console.debug() in production
if (config[REACT_APP_STAGE] === "production") {
  console.log = () => {};
  console.debug = () => {};
}

// common config variables
export const config_REACT_APP_STAGE = REACT_APP_STAGE;
export const config_REACT_APP_DOMAIN = REACT_APP_DOMAIN;
export const config_REACT_APP_DEVELOPMENT = REACT_APP_DEVELOPMENT;
export const config_domain = configuration.domain;
export const config_sentryDSN = configuration.sentryDSN;
export const config_privyAppId = configuration.privyAppId;
export const config_intercomAppId = configuration.intercomAppId;
export const config_apiEndpoint = configuration.apiEndpoint;
export const config_gtmId = configuration.gtmId;
export const config_wsEndpoint = configuration.wsEndpoint;
export const config_USDC = configuration.USDC;
