import { useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

import { setSlug } from "src/redux/slices/global";

const useSlug = () => {
  const data = useSelector(store => store.global?.slug);
  const dispatch = useDispatch();

  const setData = useCallback((payload) => {
    dispatch(setSlug(payload));
  }, [dispatch]);

  return { data, setData };
};

export default useSlug;
