import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  privyLogin    : ["email"],
  currentDialog : {
    name  : null,
    props : null,
  },
  slug: null,
};

const slice = createSlice({
  name     : "global",
  initialState,
  reducers : {
    setPrivyLogin    : (state, action) => ({ ...state, privyLogin: action.payload }),
    setCurrentDialog : (state, action) => ({ ...state, currentDialog: action.payload }),
    setSlug          : (state, action) => ({ ...state, slug: action.payload }),
  },
});

export const { setPrivyLogin, setCurrentDialog, setSlug } = slice.actions;

export default slice.reducer;
